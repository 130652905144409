<script setup>
import AdminProfile from '@/Components/Menu/AdminProfile.vue';
import Dropdown from '@/Components/Menu/Dropdown.vue';
import Item from '@/Components/Menu/Item.vue';
import { useNotification } from '@/composables/notification';
import { firebaseEnabled } from '@/firebaseConfig';
import MobileMenu from '@/Layouts/Partials/MobileMenuStaff.vue';
import { useChatStore } from '@/stores/chat';
import { useNavigationStore } from '@/stores/navigation';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import menuIcon from '@i/menu.svg';
import { Head, Link, router, usePage } from '@inertiajs/vue3';
import { onClickOutside, tryOnBeforeUnmount } from '@vueuse/core';
import { computed, inject, onMounted, onUpdated, ref } from 'vue';

const route = inject('route');
const store = useNavigationStore();
const pageProps = computed(() => usePage().props);
const chat = useChatStore();
const notification = useNotification();

if (firebaseEnabled) {
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
        if (!firebaseEnabled) {
            return;
        }

        if (!user) {
            chat.authenticateWithFirebase();
        }
    });
}

defineProps({
    title: String,
});

onMounted(async () => {
    store.setRoute(usePage().props.route.previous_name, usePage().props.route.previous);
    store.setRoute(usePage().props.route.current_name, usePage().props.route.current);

    handleNotification();
});

onUpdated(() => {
    handleNotification();
});

tryOnBeforeUnmount(() => {
    if (typeof chat.getGroupsUnsubscribe === 'function') {
        chat.getGroupsUnsubscribe();
    }
    if (typeof chat.selectGroupUnsubscribe === 'function') {
        chat.selectGroupUnsubscribe();
    }
});

const handleNotification = () => {
    if (usePage().props.flash?.notification !== null) {
        notification.show(
            usePage().props.flash.notification.message,
            usePage().props.flash.notification.sent_at,
            usePage().props.flash.notification.type,
        );
    }
};

const logout = () => {
    router.post(route('logout'));
};

const showingMobileMenu = ref(false);
const mobileMenu = ref(null);

onClickOutside(mobileMenu, () => (showingMobileMenu.value = false));
</script>

<template>
    <div>
        <Head :title="title" />

        <div class="lex force-scroll flex-col bg-gray-medium">
            <!-- Page Heading -->
            <header class="min-h-[320px] w-full bg-blue text-4 text-white xl:min-h-[360px] 2xl:min-h-[320px]">
                <div class="container flex min-h-20 items-stretch justify-between bg-blue">
                    <div class="text base hidden flex-wrap items-center gap-x-8 font-bold md:text-lg lg:flex">
                        <Link :href="route('staff.dashboard')">
                            <img
                                alt="Flexable logo"
                                class="my-6"
                                src="@/../img/flexable-logo-sunset.svg"
                            >
                        </Link>
                        <Item
                            :label="$t('Shifts')"
                            route-name="staff.shifts.index"
                        />
                        <Item
                            :label="$t('Locations')"
                            route-name="staff.locations.index"
                        />
                        <Item
                            :label="$t('Companies')"
                            route-name="staff.companies.index"
                        />
                        <Item
                            :label="$t('Invoices')"
                            route-name="staff.invoices.index"
                        />
                        <Item
                            :label="$t('Purchase invoice')"
                            route-name="staff.purchase-invoices.index"
                        />
                        <Item
                            :label="$t('Fl@xrs')"
                            route-name="staff.flexers.index"
                        />
                        <Dropdown
                            :items="[
                                { label: $t('Positions'), routeName: 'staff.positions.index' },
                                { label: $t('Reviews'), routeName: 'staff.reviews.index' },
                                { label: $t('Messages'), routeName: 'staff.chat' },
                                {
                                    label: $t('FAQs'),
                                    group: [
                                        { label: $t('FAQs'), routeName: 'staff.faqs.index' },
                                        { label: $t('Categories'), routeName: 'staff.faq-categories.index' },
                                    ],
                                },
                                {
                                    label: $t('Notifications'),
                                    group: [
                                        {
                                            label: $t('Generic'),
                                            routeName: 'staff.send-notification.create',
                                        },
                                        {
                                            label: $t('Salary'),
                                            routeName: 'staff.management.salary-notification',
                                        },
                                    ],
                                },
                            ]"
                            :label="$t('Others')"
                        />
                    </div>
                    <div class="z-10 hidden items-center gap-4 lg:flex">
                        <AdminProfile :user="pageProps.user" />
                    </div>

                    <Link
                        :href="route('staff.dashboard')"
                        class="flex lg:hidden"
                    >
                        <img
                            alt="Flexable logo"
                            class="my-6"
                            src="@/../img/flexable-logo-sunset.svg"
                        >
                    </Link>

                    <div class="flex items-center lg:hidden">
                        <button class="relative m-3 mr-0 flex h-5 w-5 items-center justify-center">
                            <img
                                :class="{ 'pointer-events-none opacity-0': showingMobileMenu }"
                                :src="menuIcon"
                                alt="open menu"
                                class="absolute transition-opacity duration-300"
                                @click="showingMobileMenu = true"
                            >
                        </button>
                    </div>
                </div>
            </header>

            <MobileMenu
                ref="mobileMenu"
                v-model:open="showingMobileMenu"
                :user="pageProps.user"
                @close="showingMobileMenu = false"
            />
            <!-- Page Content -->
            <main class="container my-15">
                <div class="relative -top-[200px] rounded-2xl bg-white p-10 shadow xl:-top-[250px]">
                    <div class="flex flex-col gap-8 xl:flex-row">
                        <div
                            v-if="$slots.sidebar"
                            class="flex flex-col gap-2 text-blue xl:w-2/6"
                        >
                            <slot name="sidebar" />
                        </div>
                        <div class="flex w-full flex-col justify-between">
                            <slot />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
