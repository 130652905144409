<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Link, usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';

const route = inject('route');
const user = computed(() => usePage().props.user);
</script>

<template>
    <div class="flex gap-4 shrink-0">
        <div class="flex flex-col items-end">
            <Link :href="route('staff.profile.index')"> {{ user.first_name }} {{ user.last_name }} </Link>
            <Menu as="div" class="relative" v-slot="{ open }">
                <MenuButton
                    class="w-full bg-transparent rounded-md shadow-sm flex-between focus:outline-none focus:z-10"
                >
                    <div class="w-full text-white border border-transparent shadow-sm flex-between rounded-l-md">
                        <p class="text-base text-white/60">Flexable admin</p>
                        <div class="">
                            <span class="sr-only">Change published status</span>
                            <img
                                src="@/../img/expand-white.svg"
                                class="w-4 h-4 ml-2 text-white duration-100 transform opacity-40"
                                :class="{ 'rotate-180': open }"
                                aria-hidden="true"
                            />
                        </div>
                    </div>
                </MenuButton>
                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <MenuItems
                        class="absolute right-0 mt-2 overflow-hidden origin-top-right bg-white divide-y rounded-md shadow-lg divide-gray-500-100 min-w-75 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                        <MenuItem>
                            <div class="">
                                <Link
                                    v-if="$page.props.user"
                                    as="button"
                                    type="button"
                                    method="POST"
                                    :href="route('logout')"
                                    class="w-full py-2 pl-3 pr-4 text-sm text-left w-ful text-pink min-h-10 hover:bg-orange hover:text-white"
                                >
                                    {{ $t('Log out') }}
                                </Link>
                            </div>
                        </MenuItem>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
        <div
            class="flex items-center justify-center w-12 h-12 overflow-hidden rounded-full text-blue"
            v-if="user.profile_media"
        >
            <Link :href="route('staff.profile.index')">
                <img
                    :src="user.profile_media.src"
                    :alt="user.profile_media.alt_text"
                    :srcset="user.profile_media.src_set"
                />
            </Link>
        </div>
        <div class="flex items-center justify-center w-12 h-12 bg-white rounded-full text-blue" v-else>
            <Link :href="route('staff.profile.index')">
                <img src="@/../img/icon/user.svg" class="h-5 min-w-5" />
            </Link>
        </div>
    </div>
</template>
