<script setup>
import AdminProfile from '@/Components/Menu/AdminProfile.vue';
import Dropdown from '@/Components/Menu/Dropdown.vue';
import Item from '@/Components/Menu/Item.vue';
import menuCloseIcon from '@i/menu-close.svg';
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

defineProps({ open: Boolean, locale: String, otherLocale: String, user: Object });
const emits = defineEmits(['close']);
const route = inject('route');
</script>

<template>
    <div
        :class="{ '-translate-y-full': !open }"
        class="fixed top-0 left-0 right-0 z-20 text-white transition-transform duration-300 bg-blue text-4"
    >
        <div class="h-8"></div>

        <div class="flex justify-between mx-10">
            <Link :href="route('location.planning.index')">
                <img alt="Flexable logo" src="@/../img/flexable-logo-sunset.svg" />
            </Link>
            <button class="relative flex items-center justify-center w-5 h-5 m-3 mr-0">
                <img
                    :src="menuCloseIcon"
                    alt="close menu"
                    class="absolute transition-opacity duration-300"
                    @click="$emit('close')"
                />
            </button>
        </div>
        <div class="h-8"></div>
        <ul class="flex flex-col mx-10 mb-2">
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <AdminProfile :user="user" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Shifts')" routeName="staff.shifts.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Locations')" routeName="staff.locations.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Companies')" routeName="staff.companies.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Invoices')" routeName="staff.invoices.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Purchase invoice')" routeName="staff.purchase-invoices.index" />
            </li>

            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Item :label="$t('Fl@xrs')" routeName="staff.flexers.index" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <Dropdown
                    location="top"
                    :items="[
                        { label: $t('Positions'), routeName: 'staff.positions.index' },
                        { label: $t('Reviews'), routeName: 'staff.reviews.index' },
                        { label: $t('Messages'), routeName: 'staff.chat' },
                        {
                            label: $t('FAQs'),
                            group: [
                                { label: $t('FAQs'), routeName: 'staff.faqs.index' },
                                { label: $t('Categories'), routeName: 'staff.faq-categories.index' },
                            ],
                        },
                        {
                            label: $t('Notifications'),
                            group: [
                                {
                                    label: $t('Generic'),
                                    routeName: 'staff.send-notification.create',
                                },
                                {
                                    label: $t('Salary'),
                                    routeName: 'staff.management.salary-notification',
                                },
                            ],
                        },
                    ]"
                    :label="$t('Others')"
                />
            </li>
        </ul>
    </div>
</template>
